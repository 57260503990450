@media (min-width:0px) {
    .about-parent {
        height: 200vh;
        color:aliceblue;
    }
}
@media (min-width:700px) {
    .about-parent {
        height: 130vh;
        color:aliceblue;
    }
}