.keyword {
    color: rgb(37, 71, 174);
    display: inline-block;
    margin-right: 10px;
    font-size: 120%;
}
.var-name {
    color: rgb(0, 166, 255);
    display: inline-block;
    margin-right: 10px;
    font-size: 120%;
}
.operator {
    color: rgb(255, 255, 255);
    display: inline-block;
    margin-right: 10px;
    font-size: 120%;
}

.quotations {
    color: #c15700;
    display: inline-block;
    font-size: 120%;
}

.terminal-window-container {
    height: 30vh;
    width: 30vw;
    min-width: 300px;
    border-style: outset;
    border-color: white  white; 
    color:black;
    border-width: 3px;
    position:relative;
}

.terminal-window-header {
    background: linear-gradient(-45deg, #6d8ce1, #00368d);
    height: 20%;
    width: 100%;
    padding: 0;
    border-style: groove;
    border-color: black;
}
.terminal-window-body {
    background-color: rgb(42, 0, 61);
    height: 80%;
    width: 100%;
    border-style:groove;
    border-color: black;
    color: black;
    text-align: left;
}

.terminal-close-tab {
    $closeTabHeight: 35px;
    background-color: rgb(202, 202, 202);
    height: $closeTabHeight;
    width: $closeTabHeight;
    border-style:none solid solid none;
    box-shadow: 2px 2px rgb(3, 0, 59);
    border-color: black;
    font-size: large;
    font-weight: 7;
    right: 5px;
    position: absolute;
    display: inline-block;
    color: black;
}

.terminal-close-tab2 {
    $closeTabHeight: 35px;
    background-color: rgb(202, 202, 202);
    height: $closeTabHeight;
    width: $closeTabHeight;
    border-style:none solid solid none;
    box-shadow: 2px 2px rgb(3, 0, 59);
    border-color: black;
    font-size: large;
    font-weight: 7;
    right: 45px;
    position: absolute;
    display: inline-block;
    color: black;
}

p {
    margin-top: 0px;
    margin-bottom: 0px;
}