.about-title {
    color: black;
}
.subtitle {
    color: black !important;
    font-size: 200%;
}

.flex-container {
    
    display: flex;
    justify-content: center;
}

.window-container {
    margin-top: 50px;
    height: 100%;
    border-style: outset;
    border-color: white  white; 
    color:black;
    border-width: 3px;
    display: inline-block;
    overflow: auto;
}

.window-header {
    background: linear-gradient(-45deg, #6d8ce1, #00368d);
    height: 50px;
    width: 100%;
    border-style: groove;
    border-color: black;
}
.window-body {
    background-color: rgb(202, 202, 202);
    height: 90%;
    width: 100%;
    border-style:groove;
    border-color: black;
    color: black;
    padding-left: 10px;
    padding-bottom: 20px;
    padding-right: 0px;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
}

.close-tab {
    background-color: rgb(202, 202, 202);
    height: 35px;
    width: 35px;
    border-style:none solid solid none;
    box-shadow: 2px 2px rgb(3, 0, 59);
    border-color: black;
    font-size: 20px;
    font-weight: 600;
    position: absolute;
    display: inline-block;
    color: black;
    text-align: center;
    margin-right: 5px;
}

.close-tab2 {
    background-color: rgb(202, 202, 202);
    height: 35px;
    width: 35px;
    border-style:none solid solid none;
    box-shadow: 2px 2px rgb(3, 0, 59);
    border-color: black;
    font-size: 20px;
    font-weight: 600;
    position: absolute;
    display: inline-block;
    color: black;
    text-align: center;
    margin-right: 15px;
}

.about-section {
    min-width: 250px;
    width: 55%;
    margin-right: 5%;
    word-wrap:break-word;
}

.image-section {
    min-width: 250px;
    width: 40%;
}

.image-section > img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20%;
    width: 90%;
    height: 70%;
    box-shadow: 4px 4px rgb(3, 0, 59);
    object-fit:cover;
}