  .accordion > input[type="checkbox"] {
    position: absolute;
    left: -100vw;
  }
  
  .accordion .content {
    overflow-y: hidden;
    height: 0;
    transition: height 0.3s ease;
  }
  
  .accordion > input[type="checkbox"]:checked ~ .content {
    height: auto;
    overflow: visible;
  }
  
  .accordion label {
    display: block;
  }
  
  .accordion {
    margin-bottom: 1em;
  }
  
  .accordion > input[type="checkbox"]:checked ~ .content {
    padding: 15px;
    width: match-parent;
    min-width: 100px;
    border: 1px solid;
    background-color: wheat;
    border-top: 0;
    margin-left: 8vw;
    margin-right: 8vw;
  }
  
  .accordion .handle {
    margin: 0;
    font-size: 1.125em;
    line-height: 1.2em;
  }
  
  .accordion label { 
    color: #333;
    border: solid;
    height: 50px;
    cursor: pointer;
    font-weight: normal;
    padding: 15px;
    background: #fffefa;
    margin-left: 8vw;
    margin-right: 8vw;
  }
  
  .accordion label:hover,
  .accordion label:focus {
    background: #d8d8d8;
  }
  
  .accordion .handle label:before {
    font-family: 'fontawesome';
    content: ">";
    display: inline-block;
    margin-right: 10px;
    font-size: .98em;
    line-height: 1.556em;
  }
  
  .accordion > input[type="checkbox"]:checked ~ .handle label:before {
    content: "^";
  }
