.blog-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    border-style: outset;
  }
  
  .blog-container > .blog-item {
    background-color: #f1f1f1;
    flex-wrap: wrap;
    color: black;
    width: 25rem;
    margin: 1%;
    text-align: center;
    height: 15rem;
    font-size: 30px;
    border-style:solid;
    border-radius: 10px;
    transition-duration: 1s;
  }

  .blog-item:hover{
    height: 20rem;
  }

.blog-image {
  object-fit: cover;
  width: 100%;
  height: 70%;
  background-attachment:unset;
}