.linkedin {
  margin-left: 10px;
  margin-right: 10px;
  width: 3.5vw;
  min-width: 35px;
  height: 3.5vw;
  min-height: 35px;
  padding:10px;
  display: inline-block;
  background-image: url(../assets/linkedin-logo.png);
  background-size: 100% 100%;
}
.github {
  margin-left: 10px;
  margin-right: 10px;
  width: 3.5vw;
  min-width: 35px;
  height: 3.5vw;
  min-height: 35px;
  padding:10px;
  display: inline-block;
  background-image: url(../assets/github.png);
  background-size: 100% 100%;
}
.codecoogs {
  margin-left: 10px;
  margin-right: 10px;
  width: 3.5vw;
  min-width: 35px;
  height: 3.5vw;
  min-height: 35px;
  padding:10px;
  display: inline-block;
  background-image: url(../assets/cocoidea1.png);
  background-size: 100% 100%;
}


.linkedin:hover {
  background-image: url(../assets/linkedin-logohovered.png);
}
.github:hover {
  background-image: url(../assets/githubhovered.png);
}
.codecoogs:hover {
  background-image: url(../assets/cocoideahovered.png);
}