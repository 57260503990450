@font-face {
    font-family: 'Glitch';
    src: url('../../assets/fonts/DigitalGlitchDemo-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Uncracked';
    src: url('../../assets/fonts/Uncracked\ Free\ Trial-6d44.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Pixel';
    src: url('../../assets/fonts/PixelEmulator-xq08.ttf') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Mario';
    src: url('../../assets/fonts/MushroomKingdomNbpRegular-RGGA.ttf') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'SNES';
    src: url('../../assets/fonts/SnesItalic-1G9Be.ttf') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Techno';
    src: url('../../assets/fonts/TechnoRaceItalic-eZRWe.otf') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@media (min-width: 0px) {
	.give-more-space {
		margin-bottom: 250px;
	}

	.home-container {
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		/*background-image: url(../../assets/vwavebackgroundsmall.gif);*/
		background-repeat:no-repeat;
		background-size: 100% 100%;
	}
	.home-title > h1 {
		font-size:calc(12px + 7.5vw);
		color: white;
		font-family: 'Techno';
	}
	.home-subtitle > h1 {
		margin-top:-15px;
		font-size:calc(12px + 0.5vw);
		color: white;
		text-align:center;
		font-family: 'Pixel';
	}
	img {
		width: 3.5vw;
		min-width: 35px;
	}
	body {
		background: linear-gradient(-45deg, #5d146c, #000000, #1b076a);
		background-size: 800% 1300%;
		animation: gradient 10s ease infinite;
		height: 1000vh;
		color: #000;
	}
	
	@keyframes gradient {
		0% {
			background-position: 0% 50%;
		}
		50% {
			background-position: 100% 50%;
		}
		100% {
			background-position: 0% 50%;
		}
	}
}
@media (min-width: 700px) {
	.home-container {
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		/*background-image: url(../../assets/vwavebackground.gif);*/
		background-repeat:no-repeat;
		background-size: 100% 100%;
	}
	.home-title > h1 {
		font-size:calc(12px + 5.5vw);
		color: white;
		font-family: 'Techno';
	}
	.home-subtitle > h1 {
		margin-top:-15px;
		font-size:calc(12px + 0.5vw);
		color: white;
		text-align:center;
		font-family: 'Pixel';
	}
	img {
		width: 3.5vw;
		min-width: 35px;
	}
	body {
		background: linear-gradient(-45deg, #3d86a2, #07233a);
		background-size: 800% 800%;
		animation: gradient 10s ease infinite;
		height: 500vh;
		color: #000;
	}
	
	@keyframes gradient {
		0% {
			background-position: 0% 50%;
		}
		50% {
			background-position: 100% 50%;
		}
		100% {
			background-position: 0% 50%;
		}
	}
}

.title-container {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	justify-content: center;
	align-items: center;
	margin-top: 25vh;
	margin-bottom: 20px;
}
.social-media-container {
	margin-top: 0;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	margin-bottom: 45vh;
}
.palm-tree {
	margin-left: 15px;
	margin-right: 15px;
}